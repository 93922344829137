*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

html {
  background-color: #f9f9f9;
  font-family: 'Sarabun', sans-serif;
  font-size: 62.5%;
  height: 100vh;
  width: 100vw;
}

body {
  box-sizing: border-box;
  font-family: 'Sarabun', sans-serif;
  font-size: 1.6rem;
  height: 100vh;
  outline: none;
  width: 100vw;
}

svg .axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

svg .x.axis path {
  display: none;
}

option {
  background-color: #ffffff;
}

select {
  appearance: none;
  background-color: #ffffff;
}

h2 {
  color: #366696;
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
  font-size: 2rem;
  font-weight: 800;
}

p,
h4 {
  color: #366696;
}

a:link,
a:visited,
a:active {
  color: #4fb9de;
}
a:hover {
  color: #00bdff;
}
